.i {
  background-color: rgba(0, 0, 0, 0.925);
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: rgba(163, 147, 147, 0.425) solid 1px;
}
.i-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.i-right {
  flex: 1;
  position: relative;
}
.i-left-wrapper {
  color: whitesmoke;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.i-intro {
  font-style: 30px;
  font-weight: 300;
}
.i-name {
  font-size: 60px;
  text-decoration: wavy;
}

.i-title {
  height: 50px;
  overflow: hidden;
}
.i-title-wrapper {
  height: 100%;
  animation: move 3s ease-in-out infinite alternate;
}
@keyframes move {
  0% {
    transform: translate(0px);
  }
  /* 50% {
    transform: translateY(-50px);
  } */
  100% {
    transform: translateY(-50px);
  }
}
.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #59b256;
  display: flex;
  align-items: center;
}

.i-projectBtn {
  text-align: center;
  /* margin: 0 auto; */
  width: 6rem;
  font-weight: bold;
  text-decoration: none;
  padding: 5px 10px;
  background-color: #59b256;
  color: black;
  transition: background-color, color, 150ms ease-in-out;
  border-radius: 3px;
  margin-top: 30px;
}

.i-projectBtn:hover {
  background-color: black;
  color: #59b256;
}

.i-img {
  height: 100%;
  width: 75%;
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 10%;
}

.i-bg {
  clip-path: polygon(100% 0%, 100% 53%, 100% 100%, 39% 100%, 0% 50%, 43% 0);
  height: 100%;
  width: 100%;
  background-color: #59b256;
  position: absolute;
  top: 0;
  right: 0;
}
.i-downArrow {
  height: 40px;
  animation: animateDown infinite 1.5s;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 100;
}
@keyframes animateDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(3px);
  }
}

@media only screen and (max-width: 600px) {
  .i {
    flex-direction: column;
  }

  .i-name {
    font-size: 2rem;
  }
  .i-downArrow {
    z-index: 100;
  }
  .i-desc {
    margin-top: 20%;
  }
}
@media only screen and (max-width: 768px) {
  .i {
    flex-direction: column;
  }
  .i-left {
    flex: 0.6;
    align-items: center;
  }
  .i-right {
    flex: 1.3;
    position: relative;
  }
}
