.a {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.925);
  color: white;
  border-bottom: rgba(255, 255, 255, 0.425) solid 1px;
}
.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.a-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  padding: 0 20px;
}
.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
}
.a-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}
.a-disc-bg {
  gap: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .a {
    flex-direction: column;
  }
  .a-right {
    height: 30%;
  }
  .a-card {
    width: 60%;
    height: 40vh;
    border-radius: 30px;
    position: relative;
  }
  .a-img {
    height: 100%;
    width: 100%;

    border-radius: 30px;
    overflow: visible;
  }
  .a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
  }
}
